import {SOLARPLEX_FEED_API} from 'lib/constants'
import {makeAutoObservable, runInAction} from 'mobx'

import {CommunityFeedModel} from '../feeds/community-feed'
import {RootStoreModel} from '../root-store'
import {SolarplexCommunity} from 'lib/splx-types'
import {actions} from '../actions'

export class JoinedCommunitiesModel {
  // simply stores ids of communities joined by current user
  communities: string[] = []

  // data
  _communityModelCache: Record<string, CommunityFeedModel> = {}

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      {autoBind: true},
    )
    this.rootStore.onSessionLoaded(() => {
      this.updateCache(true)
    })
  }
  /**
   * syncs the cached models against the current state
   * should only be called by the preferences model after syncing state
   */
  _updateCache = actions.wrapAction(
    async (did: string, clearCache?: boolean) => {
      let newCommunityModels: Record<string, CommunityFeedModel> = {}
      if (!clearCache) {
        newCommunityModels = {...this._communityModelCache}
      }

      // fetch the joined communities
      const response = await fetch(
        `${SOLARPLEX_FEED_API}/splx/get_communities_for_user/${did}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            Authorization: `Bearer ${this.rootStore.session?.currentSession?.accessJwt}`,
          },
        },
      )
      const joinedCommunities = await response.json()
      this.communities = joinedCommunities.data.map(
        (c: SolarplexCommunity) => c.id,
      )

      // collect the community IDs that haven't been synced yet
      const neededCommunityIds = this.communities?.filter(
        id => !(id in newCommunityModels),
      )

      // fetch the missing models
      for (const id of neededCommunityIds) {
        const community = await this.fetchCommunity(id)
        newCommunityModels[id] = community
      }

      // merge into the cache
      runInAction(() => {
        this._communityModelCache = newCommunityModels
      })
    },
    this,
    '_updateCache',
  )

  async updateCache(clearCache?: boolean) {
    console.log('updating cache', this.rootStore.me.did)
    if (!this.rootStore.me.did) {
      return
    }
    return await this._updateCache(this.rootStore.me.did, !!clearCache)
  }

  get isUpdatingCache() {
    return (
      this.rootStore.me.did &&
      (actions.isBusy('_updateCache', this, [this.rootStore.me.did, false]) ||
        actions.isBusy('_updateCache', this, [this.rootStore.me.did, true]))
    )
  }

  async fetchCommunity(id: string): Promise<CommunityFeedModel> {
    const response = await fetch(
      `${SOLARPLEX_FEED_API}/splx/get_community/${id}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': 'no-cors',
        },
      },
    )

    if (!response.ok) {
      throw new Error(`Failed to fetch community with ID ${id}`)
    }

    const communityData = await response.json()
    return new CommunityFeedModel(this.rootStore, communityData)
  }
  // public api
  // =

  // TODO(viksit)[F1]: joined communities may not be happening
  // lets make sure these are getting synced with the preferenecs
  _toggle = actions.wrapAction(
    async (did: string, cid: string, join: boolean) => {
      const community = this.rootStore.communities.byId(cid)
      if (!community) return
      join ? await community.join() : await community.leave()
      const url = `${SOLARPLEX_FEED_API}/${
        join ? 'join' : 'leave'
      }_community_by_id`
      await this.rootStore.api.post(url, {
        body: {did, cid},
      })
      await this._updateCache(did, true)
    },
    this,
    '_toggle',
  )

  isBusy(community: CommunityFeedModel) {
    return (
      actions.isBusy('_toggle', this, [
        this.rootStore.me.did,
        community.id,
        true,
      ]) ||
      actions.isBusy('_toggle', this, [
        this.rootStore.me.did,
        community.id,
        false,
      ])
    )
  }

  async join(community: CommunityFeedModel) {
    const did = this.rootStore.me.did
    const cid = community.id
    if (!did || !cid) {
      return
    }
    return await this._toggle(did, cid, true)
  }

  async leave(community: CommunityFeedModel) {
    const did = this.rootStore.me.did
    const cid = community.id
    if (!did || !cid) {
      return
    }
    return await this._toggle(did, cid, false)
  }

  /**
   * Nuke all data
   */
  clear() {
    this.communities = []
    this._communityModelCache = {}
  }

  // state transitions
  // =
}
