import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import React, {useEffect} from 'react'
import {useFocusEffect, useNavigation} from '@react-navigation/native'

import {ClaimBtn} from './ClaimBtn'
import {Day} from './Day'
import {NavigationProp} from 'lib/routes/types'
import {ProgressBar} from './ProgressBar'
import {ShareButton} from './ShareButton'
import {Text} from '../util/text/Text'
import {colors} from 'lib/styles'
import {observer} from 'mobx-react-lite'
import {s} from 'lib/styles'
import {s3ToCdn} from 'lib/media/util'
import {useAnalytics} from 'lib/analytics/analytics'
import {usePalette} from 'lib/hooks/usePalette'
import {useSplxWallet} from '../wallet/useSplxWallet'
import {useState} from 'react'
import {useStores} from 'state/index'
import LinearGradient from 'react-native-linear-gradient'
import {TrophyIcon} from 'lib/icons'
import {Button} from '../util/forms/Button'
import {RewardClaimButtonNavigate} from './RewardClaimBtns'
import {useTheme} from 'lib/ThemeContext'
import {isMobileWeb} from 'platform/detection'

interface RewardClaimedProps {
  userId: string
  img: string
}

interface UserRewardProps {
  userId: string
  style?: StyleProp<ViewStyle>
  missionId: string
}

interface UserClaimProps extends UserRewardProps {
  source: ImageSourcePropType
  title: string
  desc: string
}

function RewardClaimedImage({img}: RewardClaimedProps) {
  return (
    <View style={styles.DiceRollImageContainer}>
      <Image
        accessibilityIgnoresInvertColors
        source={{
          uri: s3ToCdn(img || '', 512),
        }}
        accessible={true}
        accessibilityLabel={'reward image'}
        accessibilityHint=""
        resizeMode="contain"
        style={styles.rewardImage}
      />
    </View>
  )
}

type PostRewardClaimedProps = {
  rewardsImg: string
  userId: string
  shareText: string
}

function PostRewardClaimedButton({
  userId,
  shareText,
  rewardsImg,
}: PostRewardClaimedProps) {
  return (
    <View style={styles.buttonGroup}>
      <ShareButton
        shareText={shareText}
        userId={userId}
        rewardsImg={rewardsImg}
      />
    </View>
  )
}

const RewardClaimButton = observer(function RewardClaimButton({
  userId,
  missionId,
}: {
  userId: string
  missionId: string
}) {
  const store = useStores()
  const [
    _visible,
    setVisible,
    linkedWallet,
    walletAddressFromWalletConnect,
    connectWalletIsBusy,
    _disconnectWalletIsBusy,
  ] = useSplxWallet(true)
  const isDone = store.rewards.hasClaimedMission(userId, missionId)
  const shouldClaim = store.rewards.shouldClaimMission(userId, missionId)
  const isClaiming = store.rewards.isClaimingMission(userId, missionId)
  const missionReward = store.rewards.missionReward(userId, missionId)
  const defaultText = 'Roll for your reward!'
  const [linkingWallet, setLinkingWallet] = useState<boolean>(false)
  const text = isClaiming
    ? 'Claiming...'
    : isDone
    ? 'Claimed'
    : linkingWallet
    ? 'Connecting...'
    : !linkedWallet
    ? 'Connect Wallet'
    : defaultText

  const {track} = useAnalytics()

  const claimHandler = React.useCallback(async () => {
    if (linkingWallet) {
      return
    }
    if (!linkedWallet) {
      setLinkingWallet(true)
      if (walletAddressFromWalletConnect) {
        void store.wallet.linkWallet(walletAddressFromWalletConnect)
      } else {
        setVisible(true)
      }
      return
    }

    await store.rewards.claimReward(userId, missionId)
    track('Claim:Reward', {
      type: missionId,
    })

    // This won't work b/c we need to do this from the store.
    store.me.nft.fetchNfts(linkedWallet)
  }, [
    missionId,
    linkedWallet,
    linkingWallet,
    setVisible,
    store.me.nft,
    store.rewards,
    store.wallet,
    track,
    userId,
    walletAddressFromWalletConnect,
  ])

  useEffect(() => {
    if (linkedWallet && linkingWallet) {
      setLinkingWallet(false)
      void claimHandler()
    }
  }, [linkedWallet, claimHandler, linkingWallet])

  useEffect(() => {
    if (!connectWalletIsBusy && linkingWallet) {
      setLinkingWallet(false)
    }
  }, [connectWalletIsBusy, linkingWallet])

  return (
    <View style={[styles.RollBtn]}>
      <ClaimBtn
        btnstyle={{
          paddingVertical: 12,
          paddingHorizontal: 24,
        }}
        done={isDone}
        disabled={!shouldClaim && !missionReward}
        loading={linkingWallet || isClaiming}
        text={text}
        onClick={claimHandler}
      />
    </View>
  )
})

function RewardClaimed({userId, missionId}: UserRewardProps) {
  const store = useStores()
  const reward = store.rewards.missionReward(userId, missionId)
  const isClaimWaitingOnBlockchain = store.rewards.isClaimWaitingOnBlockchain(
    userId,
    missionId,
  )
  const navigation = useNavigation<NavigationProp>()
  const theme = useTheme()

  const pal = usePalette('default')
  return (
    <>
      {reward && <RewardClaimedImage userId={userId} img={reward.image} />}
      <View style={[s.flexCol, s.alignCenter, {padding: 32}]}>
        <Text type="post-text-lg" style={[pal.text, s.bold]}>
          Congratulations!
        </Text>
        <Text
          type="lg"
          style={[{textAlign: 'center', paddingVertical: 8}, pal.text]}>
          you won{' '}
          <Text
            type="lg-bold"
            style={{
              color:
                theme.colorScheme === 'dark'
                  ? colors.white
                  : colors.splx.primary[50],
            }}>
            {reward?.name}
          </Text>
        </Text>
        <Text type="lg" style={[{textAlign: 'center'}, pal.text]}>
          Continue using Solarplex to complete missions and win more awesome
          rewards!
        </Text>
        <View style={[s.flexRow, s.alignCenter, {gap: 6}]}>
          <Button
            onPress={() => navigation.navigate('Reactions')}
            style={{
              paddingVertical: 12,
              paddingHorizontal: 24,
            }}
            labelStyle={{
              fontWeight: '700',
              color:
                theme.colorScheme === 'dark'
                  ? colors.white
                  : colors.splx.primary[50],
            }}
            type="primary-outline"
            label={
              isClaimWaitingOnBlockchain
                ? 'Adding to wallet...'
                : 'View collection'
            }
            disabled={isClaimWaitingOnBlockchain}
          />
          <PostRewardClaimedButton
            shareText={'Freshly Minted!'}
            rewardsImg={reward?.image || ''}
            userId={userId}
          />
        </View>
        <View style={[s.flexRow, s.alignCenter]}>
          <Button
            onPress={() => navigation.navigate('MissionsList')}
            style={{
              paddingVertical: 12,
              paddingHorizontal: 24,
            }}
            type="default-light"
            label="Return to Mission page"
          />
        </View>
      </View>
    </>
  )
}

const ClaimScreen = ({
  source,
  title,
  desc,
  RewardBtnComponent,
}: {
  source: ImageSourcePropType
  isWeekly?: boolean
  title: string
  desc: string
  userId: string
  RewardBtnComponent: React.ReactNode
}) => {
  const pal = usePalette('default')
  return (
    <>
      <View style={styles.DiceRollImageContainer}>
        <Image
          accessibilityIgnoresInvertColors
          source={source}
          style={styles.DiceRollImage}
        />
      </View>
      <View style={[s.flexCol, s.alignCenter, {padding: 32}]}>
        <Text
          type="post-text-lg"
          style={[pal.text, s.bold, {paddingBottom: 8}]}>
          {title}
        </Text>
        <Text type="lg" style={[{textAlign: 'center'}, pal.text]}>
          {desc}
        </Text>
        {RewardBtnComponent}
      </View>
    </>
  )
}

export function RewardClaimScreen({
  userId,
  source,
  title,
  desc,
  missionId,
}: UserClaimProps) {
  const store = useStores()
  const missionReward = store.rewards.missionReward(userId, missionId)

  return (
    <View style={styles.DiceRowCol}>
      {!missionReward ? (
        <ClaimScreen
          source={source}
          userId={userId}
          title={title}
          desc={desc}
          RewardBtnComponent={
            <RewardClaimButton userId={userId} missionId={missionId} />
          }
        />
      ) : (
        <RewardClaimed userId={userId} missionId={missionId} />
      )}
    </View>
  )
}

export function PointsProgress({
  count,
  total,
  style,
}: {
  count: number
  total: number
  style?: StyleProp<ViewStyle>
}) {
  const pal = usePalette('default')
  return (
    <View style={[style, styles.streaksContainerBox]}>
      <Text type="xs-heavy" style={[pal.text, styles.textPadding]}>
        {count ?? 0}
        <Text type="xs-heavy" style={s.gray3}>
          /{total}
        </Text>
      </Text>
    </View>
  )
}

export function WeeklyStreakProgress({count}: {count: number}) {
  return (
    <View style={styles.streaksContainerBox}>
      <View style={styles.horizontalBox}>
        {[1, 2, 3, 4, 5, 6, 7].map((day, idx) => {
          return <Day key={idx} day={day} isCompleted={day <= (count || -1)} />
        })}
      </View>
    </View>
  )
}

type MissionHeaderProps = {
  title: string
  children: React.ReactNode
}

const MissionHeader = ({title, children}: MissionHeaderProps) => {
  const pal = usePalette('default')
  return (
    <View style={styles.missionItemHeader}>
      <Text type="2xl-bold" style={pal.text}>
        {title}
      </Text>
      {children}
    </View>
  )
}

export const MissionImageContainer = ({image}: {image: string}) => {
  return (
    <View style={styles.imageContainer}>
      <Image
        accessibilityIgnoresInvertColors
        style={styles.missionImage}
        source={{
          uri: s3ToCdn(image, 128),
        }}
      />
    </View>
  )
}

export const MissionDivider = () => {
  const pal = usePalette('default')
  return (
    <View
      style={{
        height: StyleSheet.hairlineWidth,
        marginVertical: 7,
        backgroundColor: pal.border.borderColor,
      }}
    />
  )
}

type MissionMainItemProps = {
  image: string
  children?: React.ReactNode | null
  missionText: string
}

const MissionMainItem = ({
  children = null,
  image,
  missionText,
}: MissionMainItemProps) => {
  const pal = usePalette('default')
  return (
    <View style={styles.mainItemContainer}>
      <MissionImageContainer image={image} />
      <View style={styles.mainItemBody}>
        <Text type="sm" style={[pal.text]}>
          {missionText}
        </Text>
        {children}
      </View>
    </View>
  )
}

function getTimeUntilMidnightUTC() {
  const now = new Date()
  const tomorrowMidnightUTC = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1),
  )
  const timeUntilMidnightUTC =
    (tomorrowMidnightUTC.getTime() - now.getTime()) / 1000 // convert to seconds
  const hours = Math.floor(timeUntilMidnightUTC / 3600)
  const minutes = Math.floor((timeUntilMidnightUTC % 3600) / 60)
  return {hours, minutes}
}

function DailyEndTime() {
  const pal = usePalette('default')
  const timeUntilMidnightUTC = getTimeUntilMidnightUTC()

  return (
    <Text type="xs" style={pal.text}>
      Ending in:{' '}
      <Text type="xs-bold" style={pal.text}>
        {timeUntilMidnightUTC.hours} hours {timeUntilMidnightUTC.minutes}{' '}
        minutes
      </Text>
    </Text>
  )
}

type MissionSectionProps = {
  userId: string
}

const DailyMissionSection = observer(({userId}: MissionSectionProps) => {
  const pal = usePalette('default')
  const store = useStores()
  const dailyMissionId = store.rewards.dailyMissionId(userId)
  const dailyProgress = store.rewards.missionProgress(userId, dailyMissionId)
  const bannerImg = store.rewards.getRewardsBannerImage(userId, dailyMissionId)

  return (
    <View style={[styles.missionContainer, pal.border]}>
      <MissionHeader title="Daily Mission">
        <DailyEndTime />
      </MissionHeader>
      <MissionMainItem
        missionText="Create quality content and engage with others to earn 50 points in
              a day and unlock a collectible Reaction!"
        image={bannerImg || ''}>
        <View style={[s.flexRow, {paddingTop: 7}]}>
          <PointsProgress
            style={{paddingRight: 11}}
            count={!store.session ? 0 : dailyProgress.count}
            total={50}
          />

          <ProgressBar progressValue={dailyProgress.count} totalProgress={50} />
        </View>
      </MissionMainItem>
      <MissionDivider />
      <View style={styles.mainItemContainer}>
        <InfoChip
          text="Common Reaction"
          GradientColors={['#E1E1E5', '#E1E1E5']}
        />
        {/* <DailyRewardClaimButton userId={userId} /> */}
        <RewardClaimButtonNavigate userId={userId} missionId={dailyMissionId} />
      </View>
    </View>
  )
})

// const PepeMissionSection = observer(({userId}: MissionSectionProps) => {
//   const pal = usePalette('default')
//   const store = useStores()
//   const pepeClownMissionId = store.rewards.pepeClownMissionId(userId)
//   const missionProgress = store.rewards.missionProgress(
//     userId,
//     pepeClownMissionId,
//   )
//   const bannerImg = store.rewards.getRewardsBannerImage(
//     userId,
//     pepeClownMissionId,
//   )

//   return (
//     <View style={[styles.missionContainer, pal.border]}>
//       <MissionHeader title="Bonus Mission">
//         <DailyEndTime />
//       </MissionHeader>
//       <MissionMainItem
//         missionText="Accomplish this bonus mission to unlock an exclusive and limited edition Pepe Reaction!"
//         image={bannerImg || ''}>
//         <View style={[s.flexRow, {paddingTop: 7}]}>
//           <PointsProgress
//             style={{paddingRight: 11}}
//             count={!store.session ? 0 : missionProgress.count}
//             total={100}
//           />

//           <ProgressBar
//             progressValue={missionProgress.count}
//             totalProgress={100}
//           />
//         </View>
//       </MissionMainItem>
//       <MissionDivider />
//       <View style={styles.mainItemContainer}>
//         <InfoChip
//           text="Pepe Reaction"
//           GradientColors={['#FF33A0', '#FFDD00']}
//         />
//         <RewardClaimButtonNavigate
//           userId={userId}
//           missionId={pepeClownMissionId}
//         />
//       </View>
//     </View>
//   )
// })

type InfoChipProps = {
  text: string
  GradientColors: string[]
}

// This is a Badge which is part of the mission card UI
const InfoChip = ({text, GradientColors}: InfoChipProps) => {
  return (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      colors={GradientColors}
      style={{
        paddingVertical: isMobileWeb ? 4 : 8,
        paddingHorizontal: isMobileWeb ? 8 : 12,
        borderRadius: 3,
      }}>
      <View style={[s.flexRow, {flex: 1}]}>
        <TrophyIcon />
        <Text
          type="xs-bold"
          style={{
            paddingHorizontal: 4,
            color: colors.splx.primary[50],
            borderRadius: 3,
          }}>
          {text}
        </Text>
      </View>
    </LinearGradient>
  )
}

const WeeklyMissionSection = observer(
  ({userId}: Pick<MissionSectionProps, 'userId'>) => {
    const store = useStores()
    const pal = usePalette('default')

    const weeklyMissionId = store.rewards.weeklyMissionId(userId)
    const weeklyProgress = store.rewards.missionProgress(
      userId,
      weeklyMissionId,
    )
    const bannerImg = store.rewards.getRewardsBannerImage(
      userId,
      weeklyMissionId,
    )
    return (
      <View style={[styles.missionContainer, pal.border]}>
        <MissionHeader title="Weekly Mission" children={null} />
        <MissionMainItem
          missionText="Complete 7 Daily Missions in a row to earn a rare collectible reaction!"
          // TODO (Pratik) have to set the banner img to some fallback img instead of ''
          image={bannerImg || ''}>
          <View style={[s.flexRow, {paddingTop: 7}]}>
            <PointsProgress
              count={!store.session ? 0 : weeklyProgress.count}
              total={7}
              style={{paddingRight: 11}}
            />
            <WeeklyStreakProgress
              count={!store.session ? 0 : weeklyProgress.count}
            />
          </View>
        </MissionMainItem>
        <MissionDivider />
        <View style={styles.mainItemContainer}>
          <InfoChip
            text="Rare Reaction"
            GradientColors={['#E17032', '#FFB549']}
          />
          {/* <WeeklyRewardClaimButton userId={userId} />
           */}
          <RewardClaimButtonNavigate
            userId={userId}
            missionId={weeklyMissionId}
          />
        </View>
      </View>
    )
  },
)

export const MissionContainer = observer(function MissionContainer({
  userId,
}: {
  userId: string
}) {
  const store = useStores()
  const pal = usePalette('default')
  const {screen} = useAnalytics()
  useFocusEffect(
    React.useCallback(() => {
      screen('Rewards')
      if (userId !== '') {
        store.rewards.fetchMissions(userId)
      }
    }, [store, userId, screen]),
  )

  return (
    <View style={[styles.outer, s.h100pct]}>
      <Text type="title-xl" style={[s.pb10, pal.text, s.bold]}>
        Missions
      </Text>
      <View style={[s.flexCol, {gap: 16}]}>
        <DailyMissionSection userId={userId} />
        {/* <PepeMissionSection userId={userId} /> */}
        <WeeklyMissionSection userId={userId} />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  buttonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  leftgroup: {
    paddingVertical: 10,
  },
  claimBtnContainer: {
    flexShrink: 1,
  },
  dataContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
    width: '100%',
  },
  imageContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    borderWidth: 11,
    borderColor: '#F4F4F7',
    backgroundColor: s.gray3.color,
  },
  missionImage: {
    width: 41,
    height: 41,
    resizeMode: 'contain',
  },
  missionContainer: {
    flexDirection: 'column',
    padding: 16,
    borderWidth: 1,
    borderRadius: 3,
  },
  outer: {
    paddingTop: 10,
    paddingHorizontal: 24,
  },
  streaks: {
    flexDirection: 'column',
    paddingHorizontal: 14,
    flexShrink: 1,
    paddingVertical: 10,
  },
  paraTextPadding: {
    paddingBottom: 4,
    textAlign: 'left',
  },
  textPadding: {
    width: '100%',
  },
  para: {
    fontSize: 16,
    paddingTop: 4,
    textTransform: 'capitalize',
  },
  streaksContainerBox: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  streaksText: {
    fontWeight: '600',
    fontSize: 18,
  },
  horizontalBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dailyPoints: {
    fontWeight: '800',
    fontSize: 24,
    color: colors.splx.primary[50],
    // textAlign: "center",
  },
  DiceRowCol: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  DiceRollImageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    backgroundColor: colors.splx.neutral[10],
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  DiceRollText: {
    paddingVertical: 6,
  },
  RollBtn: {
    width: isMobileWeb ? '85%' : '50%',
    paddingVertical: 8,
  },
  DiceRollImage: {
    width: 200,
    height: 150,
    borderRadius: 12,
  },
  rewardImage: {
    width: 250,
    height: 250,
  },
  rewardClaimedContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 24,
    paddingHorizontal: 16,
    flex: 1,
    backgroundColor: colors.splx.neutral[10],
  },
  missionItemHeader: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    width: '100%',
    paddingBottom: 7,
    justifyContent: 'space-between',
  },
  mainItemContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  progressContainer: {
    height: 20,
    backgroundColor: colors.splx.neutral[10],
    borderRadius: 10,
    margin: 10,
  },
  bar: {
    height: 20,
    backgroundColor: colors.splx.primary[50],
    borderRadius: 10,
  },
  mainItemBody: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: 9,
  },
})
