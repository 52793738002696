import Constants from 'expo-constants'
import {CodedError, Platform, SyntheticPlatformEmitter} from 'expo-modules-core'

import {DevicePushToken} from 'expo-notifications/src/Tokens.types'
import {ExpoConfig} from 'expo/config'
import {register} from 'lib/splx-utils/singleton'
import {initializeApp} from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  MessagePayload,
} from 'firebase/messaging'
import {firebaseConfig} from 'lib/constants'
import {RootStoreModel} from '../../state/index'

const prefix = `townsquare/lib/notifications/fcm#`
const globals = register(() => {
  return {
    app: undefined as ReturnType<typeof initializeApp> | undefined,
    messaging: undefined as ReturnType<typeof getMessaging> | undefined,
  }
}, `${prefix}globals`)

export async function getDevicePushTokenAsync(): Promise<DevicePushToken> {
  const data = await _subscribeDeviceToPushNotificationsAsync()
  SyntheticPlatformEmitter.emit('onDevicePushToken', {devicePushToken: data})
  return {type: Platform.OS, data}
}

type Notification = ExpoConfig['notification'] & {
  vapidPublicKey?: string
  serviceWorkerPath?: string
  icon?: string
}

function getNotificationConfig(): Notification | undefined {
  return Constants.expoConfig?.notification
}

function guardPermission() {
  if (!('Notification' in window)) {
    throw new CodedError(
      'ERR_UNAVAILABLE',
      'The Web Notifications API is not available on this device.',
    )
  }
  if (!navigator.serviceWorker) {
    throw new CodedError(
      'ERR_UNAVAILABLE',
      'Notifications cannot be used because the service worker API is not supported on this device. This might also happen because your web page does not support HTTPS.',
    )
  }
  if (Notification.permission !== 'granted') {
    throw new CodedError(
      'ERR_NOTIFICATIONS_PERMISSION_DENIED',
      `Cannot use web notifications without permissions granted. Request permissions with "expo-permissions".`,
    )
  }
}

async function _subscribeDeviceToPushNotificationsAsync(): Promise<
  DevicePushToken['data']
> {
  const vapidPublicKey = getNotificationConfig()?.vapidPublicKey
  if (!vapidPublicKey) {
    throw new CodedError(
      'ERR_NOTIFICATIONS_PUSH_WEB_MISSING_CONFIG',
      'You must provide `notification.vapidPublicKey` in `app.json` to use push notifications on web. Learn more: https://docs.expo.dev/versions/latest/guides/using-vapid/.',
    )
  }

  const serviceWorkerPath = getNotificationConfig()?.serviceWorkerPath
  if (!serviceWorkerPath) {
    throw new CodedError(
      'ERR_NOTIFICATIONS_PUSH_MISSING_CONFIGURATION',
      'You must specify `notification.serviceWorkerPath` in `app.json` to use push notifications on the web. Please provide the path to the service worker that will handle notifications.',
    )
  }
  guardPermission()

  let registration: ServiceWorkerRegistration | null = null
  try {
    registration = await navigator.serviceWorker.register(serviceWorkerPath, {
      scope: './',
    })
  } catch (error) {
    throw new CodedError(
      'ERR_NOTIFICATIONS_PUSH_REGISTRATION_FAILED',
      `Could not register this device for push notifications because the service worker (${serviceWorkerPath}) could not be registered: ${error}`,
    )
  }
  await navigator.serviceWorker.ready

  if (!registration.active) {
    throw new CodedError(
      'ERR_NOTIFICATIONS_PUSH_REGISTRATION_FAILED',
      'Could not register this device for push notifications because the service worker is not active.',
    )
  }

  const subscriptionObject = await getToken(getFcm(), {
    vapidKey: vapidPublicKey,
  })

  // Store notification icon string in service worker.
  // This message is received by `/expo-service-worker.js`.
  // We wrap it with `fromExpoWebClient` to make sure other message
  // will not override content such as `notificationIcon`.
  // https://stackoverflow.com/a/35729334/2603230
  const notificationIcon = getNotificationConfig()?.icon
  await registration.active.postMessage(
    JSON.stringify({fromExpoWebClient: {notificationIcon}}),
  )

  return subscriptionObject
}

export function getFcm() {
  if (!globals.messaging) {
    globals.app = initializeApp(firebaseConfig)
    globals.messaging = getMessaging()
  }
  return globals.messaging
}

export function addFcmListeners(store: RootStoreModel) {
  const messaging = getFcm()
  if (!messaging) {
    console.error('Messaging not set up yet')
    return
  }
  const unsubOnFcmMessage = onMessage(messaging, (payload: MessagePayload) => {
    onFcmMessage(store, payload)
  })
  return {
    unsubOnFcmMessage,
  }
}

function onFcmMessage(store: RootStoreModel, payload: MessagePayload) {
  store.log.debug('Notifications: received', payload)
  store.me.notifications.refresh()
}
