import React from 'react'
import {Text} from '../com/util/text/Text'
import {
  NativeStackScreenProps,
  CommonNavigatorParams,
} from '../../lib/routes/types'
import {s, gradients} from '../../lib/styles'
import {usePalette} from '../../lib/hooks/usePalette'
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import {TextInput} from '../com/modals/util'
import {useStores} from '../../state'
import {useTheme} from '../../lib/ThemeContext'
import {cleanError} from '../../lib/strings/errors'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {ErrorMessage} from '../com/util/error/ErrorMessage'
import {SOLARPLEX_FEED_API} from '../../lib/constants'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'WaitList'>
export const WaitListScreen = (_props: Props) => {
  const pal = usePalette('default')
  const store = useStores()
  const theme = useTheme()
  const [email, setEmail] = React.useState<string>('')
  const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const onPressSignup = async () => {
    setError('')
    setIsProcessing(true)
    try {
      const res = await fetch(`${SOLARPLEX_FEED_API}/api/waitlist`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email}),
      })

      const resBody = await res.json()

      if (resBody.message === 'Email added to waitlist successfully.') {
        setIsEmailSent(true)
      } else {
        setError(
          resBody.error ||
            'Something went wrong. Check your email and try again.',
        )
      }
    } catch (e: any) {
      setError(cleanError(e))
    }
    setIsProcessing(false)
  }
  const onCancel = () => {
    store.shell.closeModal()
  }
  return (
    <View style={{flex: 1}}>
      <View style={[styles.container, pal.view]}>
        <View style={[styles.innerContainer, pal.view]}>
          <Text type="title-xl" style={[styles.title, pal.text]}>
            Join the waitlist
          </Text>
          <Text type="lg" style={[styles.description, pal.text]}>
            Solarplex will launch soon. Join the waitlist to try the beta before
            it's publicly available.
          </Text>
          <TextInput
            style={[styles.textInput, pal.borderDark, pal.text, s.mb10, s.mt10]}
            placeholder="Enter your email"
            placeholderTextColor={pal.textLight.color}
            autoCapitalize="none"
            autoCorrect={false}
            keyboardAppearance={theme.colorScheme}
            value={email}
            onChangeText={setEmail}
            accessible={true}
            accessibilityLabel="Email"
            accessibilityHint="Input your email to get on the Solarplex waitlist"
          />
          {error ? (
            <View style={s.mt10}>
              <ErrorMessage message={error} style={styles.error} />
            </View>
          ) : undefined}
          {isProcessing ? (
            <View style={[styles.btn, s.mt10]}>
              <ActivityIndicator />
            </View>
          ) : isEmailSent ? (
            <View style={[styles.btn, s.mt10]}>
              <FontAwesomeIcon
                icon="check"
                style={pal.text as FontAwesomeIconStyle}
              />
              <Text style={s.ml10}>
                Your email has been saved! We&apos;ll be in touch soon.
              </Text>
            </View>
          ) : (
            <>
              <TouchableOpacity
                onPress={onPressSignup}
                accessibilityRole="button"
                accessibilityHint={`Confirms signing up ${email} to the waitlist`}>
                <LinearGradient
                  colors={[gradients.purple.start, gradients.purple.end]}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 1}}
                  style={[styles.btn]}>
                  <Text type="button-lg" style={[s.white, s.bold]}>
                    Join Waitlist
                  </Text>
                </LinearGradient>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.btn, s.mt10]}
                onPress={onCancel}
                accessibilityRole="button"
                accessibilityLabel="Cancel waitlist signup"
                accessibilityHint={`Exits signing up for waitlist with ${email}`}
                onAccessibilityEscape={onCancel}>
                <Text type="button-lg" style={pal.textLight}>
                  Cancel
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    paddingBottom: 20,
  },
  title: {
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  description: {
    textAlign: 'center',
    paddingHorizontal: 22,
    marginBottom: 10,
  },
  textInput: {
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 16,
    paddingVertical: 12,
    fontSize: 20,
    marginHorizontal: 20,
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    padding: 14,
    marginHorizontal: 20,
  },
  error: {
    borderRadius: 6,
    marginHorizontal: 20,
    marginBottom: 20,
  },
})
