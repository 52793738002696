import React from 'react'
import {StyleSheet, View} from 'react-native'

import {Button} from '../../../../view/com/util/forms/Button'
import {MoneyBill} from '../../../../lib/icons'
import {OnboardingHeader} from './Header'
import {colors} from '../../../../lib/styles'
import {observer} from 'mobx-react-lite'
import {s} from '../../../../lib/styles'
import {useSplxWallet} from '../../wallet/useSplxWallet'

export const Tipping = observer(
  ({prev}: {next: () => void; prev: () => void}) => {
    const [
      _visible,
      setVisible,
      _linkedWallet,
      _connectedWallet,
      connectWalletIsBusy,
      _disconnectWalletIsBusy,
    ] = useSplxWallet(false)

    async function handleConnectWallet() {
      setVisible(true)
    }

    return (
      <View style={[styles.container]}>
        <View testID="welcomeScreen" style={[s.flexCol]}>
          <OnboardingHeader
            title="Introducing Tipping! "
            desc="Get tipped for creating great content or tip creators for their work!"
            step="3"
          />
          <View style={styles.spacer} />
          <View
            style={[
              {maxHeight: 300, minHeight: 300},
              s.flexCol,
              s.alignCenter,
              {justifyContent: 'center'},
            ]}>
            <MoneyBill size={200} fill="#969696" />
          </View>
        </View>
        <Button
          onPress={handleConnectWallet}
          style={{
            width: '100%',
            margin: 20,
            marginBottom: 0,
          }}
          disabled={connectWalletIsBusy}
          label="Connect Your Wallet"
          testID="continueBtn"
          labelStyle={styles.buttonText}
        />
        <Button
          type="default-light"
          onPress={prev}
          style={{
            width: '100%',
            margin: 20,
            marginBottom: 0,
          }}
          label="Prev"
          testID="PrevBtn"
          labelStyle={styles.prevButtonText}
        />
      </View>
    )
  },
)

const styles = StyleSheet.create({
  // ob stands for onboarding
  ob3: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  container: {
    flex: 1,
    marginVertical: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 48,
    fontWeight: '800',
  },
  row: {
    flexDirection: 'row',
    columnGap: 20,
    alignItems: 'center',
    marginVertical: 20,
  },
  rowText: {
    flex: 1,
  },
  spacer: {
    height: 20,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 18,
    marginVertical: 4,
  },
  prevButtonText: {
    textAlign: 'center',
    fontSize: 16,
    marginVertical: 2,
    color: colors.gray8,
  },
})
