import {s3ToCdn} from 'lib/media/util'
import React from 'react'
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native'
import {useStores} from 'state/index'
import {ClaimBtn} from './ClaimBtn'
import {useAnalytics} from '../../../lib/analytics/analytics'

interface RewardClaimedProps {
  rewardsImg: string
  shareText: string
  userId: string
  style?: StyleProp<ViewStyle>
  canShare?: boolean
}

export function ShareButton({
  rewardsImg,
  shareText,
  style,
  canShare = true,
}: RewardClaimedProps) {
  const store = useStores()
  const {track} = useAnalytics()
  const onPressCompose = React.useCallback(() => {
    if (store.shell.isModalActive) {
      store.shell.closeModal()
    }
    if (shareText === '') {
      track('Rewards:ReactionShare')
    }
    store.shell.openComposer({
      isSharing: true,
      uri: s3ToCdn(rewardsImg, 512),
      sharingText: shareText,
    })
  }, [store, rewardsImg, shareText, track])

  return (
    <View style={[style, styles.RollBtn]}>
      <ClaimBtn
        disabled={!canShare}
        text="Share Reward"
        onClick={onPressCompose}
        btnstyle={{
          paddingVertical: 12,
          paddingHorizontal: 24,
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  RollBtn: {
    width: '100%',
    paddingVertical: 8,
  },
})
