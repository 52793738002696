import React from 'react'
import {View} from 'react-native'
import {useFocusEffect} from '@react-navigation/native'
import {NativeStackScreenProps, CommonNavigatorParams} from '../../lib/routes/types'
import {withAuthRequired} from 'view/com/auth/withAuthRequired'
import {useStores} from 'state/index'
import {makeRecordUri} from 'lib/strings/url-helpers'
import { ViewHeader } from '../com/util/ViewHeader'
import { CommunityMembersList } from '../com/communities/CommunityMembersList'
import { useAnalytics } from 'lib/analytics/analytics'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'CommunityMembers'>
export const CommunityMembersScreen = withAuthRequired(({route}:Props) => {
  const store = useStores()
  const {cid} = route.params
  const {screen} = useAnalytics()



  useFocusEffect(
    React.useCallback(() => {
      store.communities.fetch();
      store.shell.setMinimalShellMode(false)
      screen("CommunityMembers")

    }, [store,screen]),
  )

  return (
    <View>
      <ViewHeader title="Community Members" canGoBack={true} />
      <CommunityMembersList cid={cid}  />
    </View>
  )
})