import {observer} from 'mobx-react-lite'

import {useStores} from 'state/index'
import {withAuthRequired} from 'view/com/auth/withAuthRequired'
import {NativeStackScreenProps, CommonNavigatorParams} from 'lib/routes/types'
// import {useNavigation} from '@react-navigation/native'
// import {NavigationProp} from 'lib/routes/types'
import {ScrollView} from '../com/util/Views'
import {s} from 'lib/styles'
import {CenteredView} from 'view/com/util/Views.web'
import {View} from 'react-native'
import {RewardClaimScreen} from 'view/com/rewards/MissionsContainer'
import {ViewHeader} from 'view/com/util/ViewHeader'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Mission'>
export const MissionScreen = withAuthRequired(
  observer(({route}: Props) => {
    const store = useStores()
    // const navigation = useNavigation<NavigationProp>()
    const {id} = route.params
    const did = store.session?.currentSession?.did ?? ''
    const isClaiming = store.rewards.isClaimingMission(did, id)
    const hasClaimed = store.rewards.hasClaimedMission(did, id)
    const isDailyMission = (id: string) =>
      id === store.rewards.dailyMissionId(did)
    const isWeeklyMission = (id: string) =>
      id === store.rewards.weeklyMissionId(did)
    const isPepeClownMission = (id: string) =>
      id === store.rewards.pepeClownMissionId(did)

    const getTitle = () => {
      if (isDailyMission(id)) {
        if (isClaiming) {
          return 'Reward unlocking...'
        } else if (hasClaimed) {
          return 'Congratulations!'
        } else {
          return 'Daily Reward Available!'
        }
      } else if (isWeeklyMission(id)) {
        if (isClaiming) {
          return 'Reward unlocking...'
        } else if (hasClaimed) {
          return 'Congratulations!'
        } else {
          return 'Weekly reward unlocked!'
        }
      } else if (isPepeClownMission(id)) {
        if (isClaiming) {
          return 'Reward unlocking...'
        } else if (hasClaimed) {
          return 'Congratulations!'
        } else {
          return 'Pepe Clown unlocked!'
        }
      }
    }

    const getDesc = () => {
      if (isDailyMission(id)) {
        if (isClaiming) {
          return 'Continue using Solarplex to complete missions and win more awesome rewards!'
        } else if (hasClaimed) {
          return 'You reward is being prepared! Please remain on this screen...'
        } else {
          return 'You have completed your daily streak! Click the button below to roll for your reward!'
        }
      } else if (isWeeklyMission(id)) {
        if (isClaiming) {
          return 'You reward is being prepared! Please remain on this screen...'
        } else if (hasClaimed) {
          return 'Continue using Solarplex to complete missions and win more rewards!'
        } else {
          return 'You have completed your 7 day streak! Click the button below to roll for your reward!'
        }
      } else if (isPepeClownMission(id)) {
        if (isClaiming) {
          return 'Continue using Solarplex to complete missions and win more awesome rewards!'
        } else if (hasClaimed) {
          return 'You reward is being prepared! Please remain on this screen...'
        } else {
          return 'You have completed your daily streak! Click the button below to roll for your reward!'
        }
      }
    }

    const headerTitle = () => {
      if (isDailyMission(id)) {
        return 'Daily Mission'
      } else if (isWeeklyMission(id)) {
        return 'Weekly Mission'
      } else {
        return 'Halloween Mission'
      }
    }

    const imageSrc =
      isDailyMission(id) || isPepeClownMission(id)
        ? require('../../../assets/reactions/dice.gif')
        : isWeeklyMission(id)
        ? require('../../../assets/ChestClosed.png')
        : isClaiming
        ? require('../../../assets/ChestOpening.gif')
        : null

    return (
      <View testID="MissionScreen" style={s.hContentRegion}>
        <ScrollView
          style={[s.hContentRegion]}
          scrollIndicatorInsets={{right: 1}}>
          <CenteredView style={[s.hContentRegion]}>
            <ViewHeader title={headerTitle()} canGoBack={false} />
            <RewardClaimScreen
              userId={did}
              title={getTitle() ?? ''}
              desc={getDesc() ?? ''}
              source={imageSrc}
              missionId={id}
            />
          </CenteredView>
        </ScrollView>
      </View>
    )
  }),
)
