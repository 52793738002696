import {Image} from 'expo-image'
import React from 'react'
import {StyleSheet, View} from 'react-native'
import {Text} from '../util/text/Text'
import {observer} from 'mobx-react-lite'
import {s} from 'lib/styles'
import {useStores} from 'state/index'
import {isMobileWeb} from 'platform/detection'
import {s3ToCdn} from 'lib/media/util'

export const ReactionList = observer(({reactions}: {reactions: string[]}) => {
  const store = useStores()
  // const reacts = Array.from(new Set(reactions))
  const reacts = reactions

  return (
    <View style={styles.emojiSet}>
      {reacts?.map((item, index) => {
        if (
          // index < 9 &&
          (
            store.reactions.reactionTypes[item]?.nft_metadata?.image as string
          )?.includes('http')
        ) {
          return (
            <Image
              accessibilityIgnoresInvertColors
              style={styles.image}
              key={store.reactions.reactionTypes[item]?.id}
              source={s3ToCdn(
                store.reactions.reactionTypes[item]?.nft_metadata
                  ?.image as string,
                128,
              )}
            />
          )
        } else {
          return (
            <Text
              key={item}
              style={[s.f12, {marginLeft: index ? -6 : 0, zIndex: index}]}>
              {s3ToCdn(
                store.reactions.reactionTypes[item]?.nft_metadata?.image,
                128,
              )}
            </Text>
          )
        }
      })}
    </View>
  )
})

const styles = StyleSheet.create({
  emojiSet: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: isMobileWeb ? 4 : 12,
    flexBasis: isMobileWeb ? '100%' : '66%',
  },

  image: {
    // width: '100%',
    // height: '100%',
    resizeMode: 'contain',
    width: 20,
    height: 20,
    marginLeft: -8,
  },
})
