import React, {useEffect, useMemo} from 'react'
import {observer} from 'mobx-react-lite'
import {ActivityIndicator, RefreshControl, StyleSheet, View} from 'react-native'
import {CenteredView, FlatList} from '../util/Views'
import {LikesModel, LikeItem} from '../../../state/models/lists/likes'
import {ErrorMessage} from '../util/error/ErrorMessage'
import {ProfileCardWithFollowBtn} from '../profile/ProfileCard'
import {useStores} from '../../../state/index'
import {usePalette} from '../../../lib/hooks/usePalette'
import { ProfileView, ProfileViewDetailed } from '@atproto/api/dist/client/types/app/bsky/actor/defs'
import { useFocusEffect } from '@react-navigation/native'

export const CommunityMembersList = observer(function ({cid}: {cid:string}) {
  const store = useStores()
  const profiles = store.communities.allUsersProfiles[cid];
  

   if (store.communities.fetchAllUserProfilesInCommunityBusy(cid) || profiles.length === 0 ) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }


   if (store.communities.fetchAllUserProfilesInCommunityError(cid) ) {
    return (
      <CenteredView>
        <ErrorMessage message={"Something Went Wrong"} />
      </CenteredView>
    )
  }


  const renderItem = ({item}: {item:ProfileViewDetailed }) => 
     (
      <ProfileCardWithFollowBtn key={item.did} profile={item} />
    )
  

   return (
    <FlatList
    data={profiles}
    keyExtractor={item => item.did}
    renderItem={renderItem}
    initialNumToRender={15}
    // ListFooterComponent={() => (
    //   <View style={styles.footer}>
    //     {view.isLoading && <ActivityIndicator />}
    //   </View>
    // )}
    // extraData={view.isLoading}
    // @ts-ignore our .web version only -prf
    desktopFixedHeight
  />
   )
})



const styles = StyleSheet.create({
  footer: {
    height: 200,
    paddingTop: 20,
  },
})
