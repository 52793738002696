import React from 'react'
import {Text} from '../../../../view/com/util/text/Text'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { View } from 'react-native'
import {s} from '../../../../lib/styles'
import {usePalette} from '../../../../lib/hooks/usePalette'
import { useStores } from '../../../../state'

type OnboardingHeaderParams = {
  title: string,
  desc: string,
  step: string
}

export const OnboardingHeader = ({title,desc,step} : OnboardingHeaderParams) => {
  const pal = usePalette('default')
  const store = useStores()


  const onPressSkip = React.useCallback(() => {
    store.me.mainFeed.refresh()
    store.shell.closeModal()
  },[store.me.mainFeed])

    return (
      <View style={[s.flexCol]}>
  <View style={[s.flexRow,s.alignCenter,{justifyContent:'space-between',width:'100%'}]}>
    <Text type="md-bold">
      <Text type="md-bold" style={[pal.link]}>{step}</Text> of 3
    </Text>
    <Text type='2xl-heavy' style={[pal.link]}>
    Welcome tips 💡 
    </Text>
    <TouchableOpacity onPress={onPressSkip}>
      <Text style={[pal.link]}>Skip</Text>
    </TouchableOpacity>
  </View>
  <Text type='2xl-heavy' style={{textAlign:'center',paddingVertical:15}}>{title}</Text>
  <Text type='lg-bold'  style={{textAlign:'center',color: pal.colors.textVeryLight}}>{desc}</Text>
  </View>
    )
}