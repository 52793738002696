import React, {useState} from 'react'
import {StyleSheet, View} from 'react-native'
import {colors, s} from 'lib/styles'

import {CenteredView} from 'view/com/util/Views.web'
import {MissionsTab} from './MissionsTab'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {RewardsTab} from './RewardsTab'
import {RewardsTabNavigatorParams} from 'lib/routes/types'
import {Selector} from 'view/com/util/ViewSelector'
import {ViewHeader} from 'view/com/util/ViewHeader'
import {clamp} from 'lib/numbers'
import {isMobileWeb} from 'platform/detection'
import {observer} from 'mobx-react-lite'
import {withAuthRequired} from 'view/com/auth/withAuthRequired'
import {useAnalytics} from 'lib/analytics/analytics'

const tabs = [
  {
    tabName: 'Missions',
    navName: 'MissionsTab',
  },
  {
    tabName: 'Rewards',
    navName: 'RewardsTab',
  },
]

// TODO: change the props text over here
type Props = NativeStackScreenProps<RewardsTabNavigatorParams, 'Rewards'>
export const RewardsScreen = withAuthRequired(
  observer((_props: Props) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const {screen} = useAnalytics()
    const onPressSelection = React.useCallback(
      (index: number) => {
        setSelectedIndex(clamp(index, 0, tabs.length))
      },
      [setSelectedIndex],
    )


    return (
      <View style={[s.hContentRegion]}>
        <CenteredView
          style={[
            s.hContentRegion,
            styles.container,
            // { alignItems: isMobileWeb ? "center" : "flex-start" },
          ]}>
          <ViewHeader title="Rewards" canGoBack={false} />
          <View
            style={[
              styles.selectorContainer,
              {alignItems: isMobileWeb ? 'center' : 'flex-start'},
            ]}>
            <Selector
              items={tabs.map(t => t.tabName)}
              selectedIndex={selectedIndex}
              onSelect={onPressSelection}
            />
          </View>
          {selectedIndex === 0 ? <RewardsTab /> : <MissionsTab />}
        </CenteredView>
      </View>
    )
  }),
)

const styles = StyleSheet.create({
  container: {
    borderLeftColor: colors.gray1,
    borderLeftWidth: 1,
    borderRightColor: colors.gray1,
    borderRightWidth: 1,
    flexDirection: 'column',
  },
  selectorContainer: {
    flexDirection: 'column',
  },
})
