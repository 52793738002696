import * as Toast from '../util/Toast'

import {DropdownButton, DropdownItem} from '../util/forms/DropdownButton'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native'
import {gradients, s} from 'lib/styles'
import {isDesktopWeb, isNative} from 'platform/detection'

import {BlurView} from '../util/BlurView'
import {CommunityFeedModel} from '../../../state/models/feeds/community-feed'
import {Link} from '../util/Link'
import {LoadingPlaceholder} from '../util/LoadingPlaceholder'
import {NavigationProp} from 'lib/routes/types'
import React from 'react'
import {SOLARPLEX_USER_DID} from 'lib/constants'
import {Text} from '../util/text/Text'
import {UserAvatar} from '../util/UserAvatar'
import {UserBanner} from '../util/UserBanner'
import {observer} from 'mobx-react-lite'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {shareUrl} from 'lib/sharing'
import {toShareUrl} from 'lib/strings/url-helpers'
import {useAnalytics} from 'lib/analytics/analytics'
import {useNavigation} from '@react-navigation/native'
import {usePalette} from 'lib/hooks/usePalette'
import {useStores} from 'state/index'
import {Button} from '../util/forms/Button'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
const BACK_HITSLOP = {left: 30, top: 30, right: 30, bottom: 30}

interface Props {
  view: CommunityFeedModel
  onRefreshAll: () => void
  hideBackButton?: boolean
}

export const CommunityHeader = observer(
  ({view, onRefreshAll, hideBackButton = false}: Props) => {
    const pal = usePalette('default')
    const insets = useSafeAreaInsets()
    // loading
    // =
    if (!view || !view.hasLoaded) {
      return (
        <View style={[pal.view, {paddingTop: insets.top}]}>
          <LoadingPlaceholder width="100%" height={120} />
          <View
            style={[
              pal.view,
              {borderColor: pal.colors.background},
              styles.avi,
            ]}>
            <LoadingPlaceholder width={80} height={80} style={styles.br40} />
          </View>
          <View style={styles.content}>
            <View style={[styles.buttonsLine]}>
              <LoadingPlaceholder width={100} height={31} style={styles.br50} />
            </View>
            <View>
              {/* <Text type="title-2xl" style={[pal.text, styles.title]}>
                {"Loading"}
              </Text> */}
            </View>
          </View>
        </View>
      )
    }

    // error
    // =
    if (view.hasError) {
      return (
        <View style={{paddingTop: insets.top}} testID="communityHeaderHasError">
          <Text>{view.error}</Text>
        </View>
      )
    }

    // loaded
    // =
    return (
      <CommunityHeaderLoaded
        view={view}
        onRefreshAll={onRefreshAll}
        hideBackButton={hideBackButton}
      />
    )
  },
)

const CommunityHeaderLoaded = observer(
  ({view, hideBackButton = false}: Props) => {
    const pal = usePalette('default')
    const store = useStores()
    const navigation = useNavigation<NavigationProp>()
    const {track} = useAnalytics()
    const insets = useSafeAreaInsets()

    const onPressBack = React.useCallback(() => {
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        navigation.navigate('Home')
      }
    }, [navigation])

    const onPressAvi = React.useCallback(() => {
      // if (view.data) {
      //   store.shell.openLightbox(new ProfileImageLightbox(view));
      // }
    }, [])

    const onPressToggleJoin = React.useCallback(async () => {
      // track(
      //   view.viewer.following
      //     ? "CommunityHeader:FollowButtonClicked"
      //     : "CommunityHeader:UnfollowButtonClicked",
      // );
      if (view.isJoined) {
        // leave
        track('CommunityHeader:LeaveButtonClicked')
        await store.me.joinedCommunities.leave(view)
        Toast.show('Removed from my communities')
      } else {
        // join
        track('CommunityHeader:JoinButtonClicked')
        await store.me.joinedCommunities.join(view)
        Toast.show('Added to my communities')
      }
    }, [track, view, store.me.joinedCommunities])

    const onPressShare = React.useCallback(() => {
      track('CommunityHeader:ShareButtonClicked')
      const url = toShareUrl(`/community/${view.data?.id}`)
      shareUrl(url)
    }, [track, view])

    // const isMe = React.useMemo(() => true, [])
    const dropdownItems: DropdownItem[] = React.useMemo(() => {
      let items: DropdownItem[] = [
        {
          testID: 'communityHeaderDropdownShareBtn',
          label: 'Share',
          onPress: onPressShare,
        },
      ]
      return items
    }, [onPressShare])

    return (
      <>
        {view && view.data && (
          <View style={[pal.view, {paddingTop: insets.top}]}>
            <UserBanner banner={view.data.banner} />
            <View style={styles.content}>
              <View style={[styles.buttonsLine]}>
                {view.data.id === 'splx-art' ? (
                  <></>
                ) : view.isJoined === true ? (
                  <Button
                    testID="leaveBtn"
                    onPress={onPressToggleJoin}
                    style={[styles.btn, styles.mainBtn, pal.btn]}
                    // accessibilityRole="button"
                    accessibilityLabel={`Leave ${view.data.name}`}
                    accessibilityHint={`Leave ${view.data.name}`}>
                    <Text type="button" style={pal.text}>
                      Joined
                    </Text>
                  </Button>
                ) : (
                  <Button
                    testID="joinBtn"
                    onPress={onPressToggleJoin}
                    style={[styles.btn, styles.primaryBtn]}
                    accessibilityLabel={`Join ${view.data.name}`}
                    accessibilityHint={`Joins ${view.data.name}`}>
                    <Text type="button" style={[s.white, s.bold]}>
                      Join
                    </Text>
                  </Button>
                )}
                {dropdownItems?.length ? (
                  <DropdownButton
                    testID="communityHeaderDropdownBtn"
                    type="bare"
                    items={dropdownItems}
                    style={[styles.btn, styles.secondaryBtn, pal.btn]}>
                    <FontAwesomeIcon icon="ellipsis" style={[pal.text]} />
                  </DropdownButton>
                ) : undefined}
              </View>
              <View>
                <Text
                  testID="communityHeaderDisplayName"
                  type="title-2xl"
                  style={[pal.text, styles.title]}>
                  {sanitizeDisplayName(view.data?.name)}
                </Text>
                <Link
                  href={`/profile/${SOLARPLEX_USER_DID}`}
                  asAnchor
                  style={[pal.textLight]}>
                  by @solarplex
                </Link>
              </View>
              <View style={[{paddingBottom: 10}, pal.text]}>
                {sanitizeDisplayName(view.data?.description)}
                {view.id === 'splx-art' && (
                  <Link href="https://tally.so/r/mVQOQj" asAnchor={true}>
                    <Text
                      style={[
                        {
                          textDecorationLine: 'underline',
                          fontSize: 16,
                          fontFamily: 'Manrope',
                        },
                        pal.link,
                      ]}>
                      Apply to be added as an artist here!
                    </Text>
                  </Link>
                )}
              </View>
            </View>
            {!isDesktopWeb && !hideBackButton && (
              <TouchableWithoutFeedback
                onPress={onPressBack}
                hitSlop={BACK_HITSLOP}
                accessibilityRole="button"
                accessibilityLabel="Back"
                accessibilityHint="">
                <View style={[styles.backBtnWrapper, {paddingTop: insets.top}]}>
                  <BlurView style={[styles.backBtn]} blurType="dark">
                    <FontAwesomeIcon
                      size={18}
                      icon="angle-left"
                      style={s.white}
                    />
                  </BlurView>
                </View>
              </TouchableWithoutFeedback>
            )}
            <TouchableWithoutFeedback
              testID="communityHeaderAviButton"
              onPress={onPressAvi}
              accessibilityRole="image"
              accessibilityLabel={`View ${view.data.name} Community`}
              accessibilityHint="">
              <View
                style={[
                  pal.view,
                  {borderColor: pal.colors.background},
                  styles.avi,
                ]}>
                {/**add image url to type */}
                <UserAvatar size={80} avatar={view.data.image} />
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
      </>
    )
  },
)

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    height: 120,
  },
  backBtnWrapper: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 30,
    height: 30,
    overflow: 'hidden',
    borderRadius: 15,
  },
  backBtn: {
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avi: {
    position: 'absolute',
    top: 110,
    left: 10,
    width: 84,
    height: 84,
    borderRadius: 42,
    borderWidth: 2,
  },
  content: {
    paddingTop: 8,
    paddingHorizontal: 14,
    paddingBottom: 4,
  },

  buttonsLine: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginBottom: 12,
  },
  primaryBtn: {
    backgroundColor: gradients.purple.start,
    paddingHorizontal: 24,
    paddingVertical: 6,
  },
  mainBtn: {
    paddingHorizontal: 24,
  },
  secondaryBtn: {
    paddingHorizontal: 14,
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 7,
    borderRadius: 50,
    marginLeft: 6,
  },
  title: {lineHeight: 38},

  // Word wrapping appears fine on
  // mobile but overflows on desktop
  handle: isNative
    ? {}
    : {
        // @ts-ignore web only -prf
        wordBreak: 'break-all',
      },

  handleLine: {
    flexDirection: 'row',
    marginBottom: 8,
  },

  metricsLine: {
    flexDirection: 'row',
    marginBottom: 8,
  },

  description: {
    flex: 1,
    marginBottom: 8,
  },

  detailLine: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },

  pill: {
    borderRadius: 4,
    paddingHorizontal: 6,
    paddingVertical: 2,
  },

  moderationLines: {
    gap: 6,
  },

  moderationNotice: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 14,
    gap: 8,
  },

  br40: {borderRadius: 40},
  br50: {borderRadius: 50},
})
