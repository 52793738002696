import {s} from 'lib/styles'
import {CenteredView} from 'view/com/util/Views.web'
import {MissionContainer} from 'view/com/rewards/MissionsContainer'
import {View} from 'react-native'
import {ViewHeader} from 'view/com/util/ViewHeader'
import {observer} from 'mobx-react-lite'
import {useStores} from 'state/index'
import {withAuthRequired} from 'view/com/auth/withAuthRequired'
import {ScrollView} from '../com/util/Views'

// TODO: change the props text over here
// type Props = NativeStackScreenProps<CommonNavigatorParams, "Missions">;
export const RewardsTab = withAuthRequired(
  observer(() => {
    const store = useStores()
    const did = store.session?.currentSession?.did ?? ''

    return (
      <View testID="RewardsScreen" style={s.hContentRegion}>
        <ScrollView
          style={[s.hContentRegion]}
          scrollIndicatorInsets={{right: 1}}>
          <CenteredView style={[s.hContentRegion]}>
            <ViewHeader title="Missions" canGoBack={false} />
            <MissionContainer userId={did} />
          </CenteredView>
        </ScrollView>
      </View>
    )
  }),
)
