import getGlobal from 'globalthis'
import {Insets} from 'react-native'
import Constants from 'expo-constants'

const HELP_DESK_LANG = 'en-us'
export const HELP_DESK_URL = `https://blueskyweb.zendesk.com/hc/${HELP_DESK_LANG}`

export const SOLARPLEX_INTENT_LINK =
  'https://twitter.com/intent/tweet?text=Let%27s%20get%20out%20of%20the%20echo%20chamber%21%20I%27m%20on%20the%20waitlist%20for%20@solarplex_xyz%20V2%21%20I%20need%20an%20invite%2C%20who%27s%20got%20one%3F'

export const DMCA_LINK =
  'https://usedispatch.notion.site/Community-Guidelines-1224767f032a4c8bab11d85abe659a70?pvs=4'

const BASE_FEEDBACK_FORM_URL = `${HELP_DESK_URL}/requests/new`
export function FEEDBACK_FORM_URL({
  email,
  handle,
}: {
  email?: string
  handle?: string
}): string {
  let str = BASE_FEEDBACK_FORM_URL
  if (email) {
    str += `?tf_anonymous_requester_email=${encodeURIComponent(email)}`
    if (handle) {
      str += `&tf_17205412673421=${encodeURIComponent(handle)}`
    }
  }
  return (
    'https://twitter.com/intent/tweet?text=Hey%20@solarplex_xyz%20I%20have%20some%20feedback%20for%20Live%3A' ??
    str
  )
}

// Global constants

export const MAX_DISPLAY_NAME = 64
export const MAX_DESCRIPTION = 256

export const MAX_GRAPHEME_LENGTH = 300
export const POST_IMG_MAX = {
  width: 2000,
  height: 2000,
  size: 1000000,
}
// Recommended is 100 per: https://www.w3.org/WAI/GL/WCAG20/tests/test3.html
// but increasing limit per user feedback
export const MAX_ALT_TEXT = 300

export const STATUS_PAGE_URL = 'placeholder'

// Solarplex realm based for environments and testing
// For now, since we've set up the environment in a way
// that staging. and live. are staging and prod respectively
// we'll continue to use those definitions.
// the realm is useful only for internal solarplex services
// such as the v1 API for incentives.
const SOLARPLEX_REALM = process.env.SOLARPLEX_REALM || 'dev'

const PROD_CONSTANTS = {
  SPLX_FEED_DB: 'dispatch-services:us-central1:solnews',
  SPLX_PLC_URL: 'https://plc.solarplex.xyz',
  SPLX_PDS_URL: 'https://live.solarplex.xyz',
  SPLX_USER_HANDLE: 'solarplex.live.solarplex.xyz',
  SPLX_USER_DID: 'did:plc:4srpaai54v3d35bigtfbtbd5',
  SPLX_V1_API: 'https://prod.api.solarplex.xyz',
  HELIUS_RPC_API: 'https://rpc.helius.xyz',
  RPC_API: 'https://try-rpc.mainnet.solana.blockdaemon.tech',
  SPLX_UI_URL: 'https://solarplex.xyz',
  SPLX_FEED_API: 'https://feed.solarplex.xyz',
  S3_IMAGE_BUCKET: 'https://splx-prod.s3.amazonaws.com',
  CLOUDFRONT_IMAGE_BUCKET: 'https://d2pys5jca4hywh.cloudfront.net',
  SPLX_MIXPANEL_WRITE_KEY: 'aqLhAgXweRt74dGXU3NVMqW9LVB2dmZ9',
  HANDLE_SUFFIX: '.live.solarplex.xyz',
}

const STAGING_CONSTANTS = {
  SPLX_FEED_DB: 'dispatch-services:us-central1:solnews-staging',
  SPLX_PLC_URL: 'https://staging.plc.solarplex.xyz',
  SPLX_PDS_URL: 'https://staging.live.solarplex.xyz',
  SPLX_USER_HANDLE: 'spx.staging.live.solarplex.xyz',
  SPLX_USER_DID: 'did:plc:aen2rosf555soqeup26zomir',
  SPLX_V1_API: 'https://dev.api.solarplex.xyz',
  HELIUS_RPC_API: 'https://devnet.helius-rpc.com',
  RPC_API: 'https://api.devnet.solana.com',
  SPLX_UI_URL: 'https://staging.v2.solarplex.xyz',
  SPLX_FEED_API: 'https://staging.feed.solarplex.xyz',
  S3_IMAGE_BUCKET: 'https://splx-staging.s3.amazonaws.com',
  CLOUDFRONT_IMAGE_BUCKET: 'https://d30z0599kmgs5o.cloudfront.net',
  SPLX_MIXPANEL_WRITE_KEY: 'zmQ00bqOYQt6ehtV14st0r6HjpcOHpFt',
  HANDLE_SUFFIX: '.staging.live.solarplex.xyz',
}

const SBX_CONSTANTS = {
  SPLX_FEED_DB: 'dispatch-services:us-west1:sandbox-db',
  SPLX_PLC_URL: 'https://sbx.plc.solarplex.xyz',
  SPLX_PDS_URL: 'https://sbx.live.solarplex.xyz',
  SPLX_USER_HANDLE: 'spx.sbx.live.solarplex.xyz',
  SPLX_USER_DID: 'did:plc:7exqskakru4whi6xwc2tz55c',
  SPLX_V1_API: 'https://dev.api.solarplex.xyz',
  HELIUS_RPC_API: 'https://devnet.helius-rpc.com',
  RPC_API: 'https://api.devnet.solana.com',
  SPLX_UI_URL: 'https://sbx.v2.solarplex.xyz',
  SPLX_FEED_API: 'https://sbx.feed.solarplex.xyz',
  S3_IMAGE_BUCKET: 'https://splx-staging.s3.amazonaws.com',
  CLOUDFRONT_IMAGE_BUCKET: 'https://d30z0599kmgs5o.cloudfront.net',
  SPLX_MIXPANEL_WRITE_KEY: 'zmQ00bqOYQt6ehtV14st0r6HjpcOHpFt',
  HANDLE_SUFFIX: '.sbx.live.solarplex.xyz',
}

const LOCALHOST_CONSTANTS = {
  SPLX_PDS_URL: 'http://localhost:2583',
  SPLX_V1_API: 'http://localhost:3001',
  SPLX_UI_URL: 'http://localhost:19006',
  SPLX_USER_DID: 'did:plc:xbw5dhmqc7cskv2dhkhsjzk6', // Note: This changes every time, so update with did output from dev-env command line call
  SPLX_FEED_API: 'http://localhost:3000',
  S3_IMAGE_BUCKET: 'https://splx-staging.s3.amazonaws.com',
}

let ACTIVE_CONSTANTS
switch (SOLARPLEX_REALM) {
  case 'prod': {
    ACTIVE_CONSTANTS = PROD_CONSTANTS
    break
  }
  case 'local': {
    ACTIVE_CONSTANTS = {...STAGING_CONSTANTS, ...LOCALHOST_CONSTANTS}
    break
  }
  case 'dev':
  case 'staging': {
    ACTIVE_CONSTANTS = STAGING_CONSTANTS
    break
  }
  default: {
    ACTIVE_CONSTANTS = SBX_CONSTANTS
    break
  }
}

export const vapidKey: string | undefined = (
  Constants.expoConfig?.notification as any
)?.vapidPublicKey

export const firebaseConfig = {
  apiKey: 'AIzaSyDMK8cttzoy8x6-wZWaTmbQFerx9Klvi2k',
  authDomain: 'townsquare-f9d3c.firebaseapp.com',
  projectId: 'townsquare-f9d3c',
  storageBucket: 'townsquare-f9d3c.appspot.com',
  messagingSenderId: '425749059511',
  appId: '1:425749059511:web:7573b402eb751a19273a32',
  measurementId: 'G-JTTF0H69K2',
}

// ("constants:", ACTIVE_CONSTANTS);
// console.log("did:", SOLARPLEX_DID);

// Bsky variables which we don't touch
export function IS_LOCAL_DEV(url: string) {
  return url.includes('localhost')
}

export function IS_STAGING(url: string) {
  return !IS_LOCAL_DEV(url) && !IS_PROD(url)
}

export function IS_PROD(url: string) {
  // NOTE
  // until open federation, "production" is defined as the main server
  // this definition will not work once federation is enabled!
  // -prf
  return url.startsWith('https://live.solarplex.xyz')
}

const currUrl = getGlobal().location?.href ?? ''

if (IS_LOCAL_DEV(currUrl) && SOLARPLEX_REALM === 'prod') {
  ACTIVE_CONSTANTS.SPLX_V1_API = LOCALHOST_CONSTANTS.SPLX_V1_API
}
export const SOLARPLEX_IDENTIFIER = ACTIVE_CONSTANTS.SPLX_USER_HANDLE

export const SOLARPLEX_FEED_API = ACTIVE_CONSTANTS.SPLX_FEED_API
// export const SOLARPLEX_FEED_API = "http://localhost:3000";
//export const SOLARPLEX_FEED_API = SOLARPLEX_FEED_API_LOCAL;
// export const SOLARPLEX_FEED_API = STAGING_CONSTANTS.SPLX_FEED_API;

export const SOLARPLEX_DID = ACTIVE_CONSTANTS.SPLX_USER_DID
export const SOLARPLEX_V1_API = ACTIVE_CONSTANTS.SPLX_V1_API
// export const SOLARPLEX_V1_API = 'http://localhost:3001'
export const HELIUS_RPC_API = ACTIVE_CONSTANTS.HELIUS_RPC_API
export const SOLARPLEX_USER_DID = ACTIVE_CONSTANTS.SPLX_USER_DID
export const SOLARPLEX_UI_URL = ACTIVE_CONSTANTS.SPLX_UI_URL
export const SOLARPLEX_PDS_URL = ACTIVE_CONSTANTS.SPLX_PDS_URL
// export const SOLARPLEX_PDS_URL = 'http://localhost:2583'
export const SPLX_MIXPANEL_WRITE_KEY = ACTIVE_CONSTANTS.SPLX_MIXPANEL_WRITE_KEY
export const RPC_API = ACTIVE_CONSTANTS.RPC_API
export const HANDLE_SUFFIX = ACTIVE_CONSTANTS.HANDLE_SUFFIX
export const SOLARPLEX_IS_DEV =
  IS_LOCAL_DEV(currUrl) ||
  SOLARPLEX_REALM === 'sbx' ||
  SOLARPLEX_REALM === 'dev' ||
  SOLARPLEX_REALM === 'local'

export const PROD_TEAM_HANDLES = [
  'viksit.live.solarplex.xyz',
  'zayyan.live.solarplex.xyz',
  'pratik.live.solarplex.xyz',
  'robert.live.solarplex.xyz',
  'partyman.live.solarplex.xyz',
]
export const STAGING_TEAM_HANDLES = []
export const DEV_TEAM_HANDLES = ['alice.test', 'bob.test', 'carla.test']

export function TEAM_HANDLES(serviceUrl: string) {
  if (serviceUrl.includes('localhost')) {
    return DEV_TEAM_HANDLES
  } else if (serviceUrl.includes('staging')) {
    return STAGING_TEAM_HANDLES
  } else {
    return PROD_TEAM_HANDLES
  }
}

export const STAGING_DEFAULT_FEED = (rkey: string) =>
  `at://${STAGING_CONSTANTS.SPLX_USER_DID}/app.bsky.feed.generator/${rkey}`
export const PROD_DEFAULT_FEED = (rkey: string) =>
  `at://${PROD_CONSTANTS.SPLX_USER_DID}/app.bsky.feed.generator/${rkey}`

export async function DEFAULT_FEEDS(
  serviceUrl: string,
  resolveHandle: (name: string) => Promise<string>,
) {
  if (IS_LOCAL_DEV(serviceUrl)) {
    // uncomment for local dev
    // local dev
    const splxDid = await resolveHandle(SOLARPLEX_USER_DID)
    return {
      pinned: [`at://${splxDid}/app.bsky.feed.generator/alice-favs`],
      saved: [`at://${splxDid}/app.bsky.feed.generator/alice-favs`],
    }
  } else if (IS_STAGING(serviceUrl) || SOLARPLEX_REALM == 'dev') {
    // staging
    return {
      pinned: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
      saved: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
    }
  } else {
    // production
    return {
      pinned: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
      saved: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
    }
  }
}

// TODO(viksit): what is this?
export const STAGING_LINK_META_PROXY =
  'https://cardyb.staging.bsky.dev/v1/extract?url='

export const PROD_LINK_META_PROXY = 'https://cardyb.bsky.app/v1/extract?url='

// Hitslop constants
export const createHitslop = (size: number): Insets => ({
  top: size,
  left: size,
  bottom: size,
  right: size,
})
export const HITSLOP_10 = createHitslop(10)
export const HITSLOP_20 = createHitslop(20)
export const HITSLOP_30 = createHitslop(30)
export const BACK_HITSLOP = HITSLOP_30

export const RECOMMENDED_FEEDS = [
  {
    did: 'did:plc:hsqwcidfez66lwm3gxhfv5in',
    rkey: 'aaaf2pqeodmpy',
  },
]

export const SOLARPLEX_FEEDS = [
  `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/splx-solana`,
  `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/splx-solarplex`,
  `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/wearesquidz`,
]

export const S3_IMAGE_BUCKET = ACTIVE_CONSTANTS.S3_IMAGE_BUCKET
export const CLOUDFRONT_IMAGE_BUCKET = ACTIVE_CONSTANTS.CLOUDFRONT_IMAGE_BUCKET

export const SOLARPLEX_FEED_URI_PATH = `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/`

export function LINK_META_PROXY(serviceUrl: string) {
  if (IS_LOCAL_DEV(serviceUrl)) {
    return STAGING_LINK_META_PROXY
  } else if (IS_STAGING(serviceUrl)) {
    return STAGING_LINK_META_PROXY
  } else {
    return PROD_LINK_META_PROXY
  }
}

export function isProdTeamHandle(handle: string): boolean {
  return PROD_TEAM_HANDLES.includes(handle)
}

export const InviteCodes = {
  breakpoint: 'live-solarplex-xyz-kjgyg-rduvv',
}

export const bannerImages = {
  daily:
    'https://splx-prod.s3.amazonaws.com/reactions/bert/images/404-nostalgia.png',
  weekly:
    'https://splx-prod.s3.amazonaws.com/reactions/bert/images/fire-nostalgia.png',
  pepe: 'https://splx-staging.s3.amazonaws.com/reactions/pepeclown/images/zombie.png',
}
