import {makeAutoObservable, runInAction} from 'mobx'
import {RootStoreModel} from './root-store'
import {SOLARPLEX_FEED_API} from 'lib/constants'
import {actions} from './actions'

export type UserInfo = {
  [did: string]: {
    handle: string
    wallet: string
    verified: boolean
  }
}

export class SplxUsersModel {
  userInfo: UserInfo = {}

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(this, {rootStore: false}, {autoBind: true})
  }

  fetchUser = actions.wrapAction(
    async (did: string) => {
      const userInfo = (
        (await this.rootStore.api.get(
          `${SOLARPLEX_FEED_API}/splx/get_user/${did}`,
        )) as any
      ).user
      runInAction(() => {
        this.userInfo[did] = userInfo as any
      })
    },
    this,
    'fetchUser',
  )

  getUserInfo(did: string) {
    return this.userInfo[did]
  }

  fetchUserIsBusy(did: string) {
    return (
      actions.isBusy('fetchUser', this, [this.rootStore.me.did, did, true]) ||
      actions.isBusy('fetchUser', this, [this.rootStore.me.did, did, false])
    )
  }
}
