/**
 * This is a temporary client-side system for storing muted threads
 * When the system lands on prod we should switch to that
 */

import {SOLARPLEX_DID, SOLARPLEX_FEED_API} from 'lib/constants'
import {hasProp, isObj} from 'lib/type-guards'
import {makeAutoObservable, runInAction} from 'mobx'

import {CommunityFeedModel} from './feeds/community-feed'
import {PostsFeedModel} from './feeds/posts'
import {RootStoreModel} from './root-store'
import {SolarplexCommunity} from 'lib/splx-types'
import {actions} from './actions'
import {makeRecordUri} from 'lib/strings/url-helpers'
import merge from 'lodash.merge'
import { AppBskyActorDefs } from '@atproto/api'
import {Sections} from './ui/profile'
import { ProfileViewDetailed } from '@atproto/api/dist/client/types/app/bsky/actor/defs'

interface CommunitiesMap {
  [id: string]: {
    idx: number
    community: SolarplexCommunity
  }
}

interface CommunityFeedMap {
  [id: string]: {
    idx: number
    communityFeed: CommunityFeedModel
  }
}

interface CommunityPostsFeedMap {
  [id: string]: {
    idx: number
    communityPostsFeed: PostsFeedModel
  }
}

type member = {
  uid: string
}

export class CommunitiesModel {
  private _communities: CommunitiesMap = {}
  private _communityFeeds: CommunityFeedMap = {}
  private _communityPostsFeeds: CommunityPostsFeedMap = {}
  public usersByCommunity: Record<string, member[]> = {}
  public communityUsersProfiles: Record<string,AppBskyActorDefs.ProfileViewDetailed[]>= {}
  private _communityUsersProfilesCache: Record<string, AppBskyActorDefs.ProfileViewDetailed[]> = {};
  public allUsersProfiles: Record<string,AppBskyActorDefs.ProfileViewDetailed[]>= {}
  private _allUsersProfilesCache: Record<string, AppBskyActorDefs.ProfileViewDetailed[]> = {};

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(
      this,
      {rootStore: false, serialize: false, hydrate: false},
      {autoBind: true},
    )
  }

  get communities(): SolarplexCommunity[] {
    return Object.values(this._communities)
      .sort((a, b) => {
        if (!a || !b) return -1
        return a.idx - b.idx
      })
      .map(i => i.community)
  }

  get communityFeeds(): CommunityFeedModel[] {
    return Object.values(this._communityFeeds)
      .sort((a, b) => {
        if (!a || !b) return -1
        return a.idx - b.idx
      })
      .map(i => i.communityFeed)
  }

  get communityPostsFeeds(): PostsFeedModel[] {
    return Object.values(this._communityPostsFeeds)
      .sort((a, b) => {
        if (!a || !b) return -1
        return a.idx - b.idx
      })
      .map(i => i.communityPostsFeed)
  }

  byId(cid: string) {
    return this.communityFeeds.find(i => i.id === cid)
  }

  serialize() {
    return {communities: this.communities}
  }

  hydrate(v: unknown) {
    if (
      isObj(v) &&
      hasProp(v, 'communities') &&
      Array.isArray(v.communities) // check if v.communities is an array
    ) {
      // ensure that every item in the array is a SolarplexCommunity
      const isValidSolarplexCommunityArray = v.communities.every(
        (item: any) =>
          typeof item === 'object' &&
          item !== null &&
          'id' in item &&
          'name' in item &&
          'description' in item &&
          'createdAt' in item &&
          'published' in item &&
          'banner' in item &&
          'uri' in item &&
          'image' in item,
      )

      if (isValidSolarplexCommunityArray) {
        this._setCommunities(v.communities as SolarplexCommunity[])
      }
    }
  }

  _setCommunities(communities: SolarplexCommunity[], reset: boolean = false) {
    // Gotta do this so we don't clobber a pointer and make components flash.
    const map = communities.reduce<CommunitiesMap>((acc, community, idx) => {
      acc[community.id] = {
        idx,
        community,
      }
      return acc
    }, {})
    const feedMap = communities.reduce<CommunityFeedMap>(
      (acc, community, idx) => {
        if (!this._communityFeeds[community.id] || reset) {
          acc[community.id] = {
            idx,
            communityFeed: new CommunityFeedModel(
              this.rootStore,
              community.id,
              community,
            ),
          }
        }
        return acc
      },
      {},
    )
    const postsFeedMap = communities.reduce<CommunityPostsFeedMap>(
      (acc, community, idx) => {
        if (
          community.uri &&
          (!this._communityPostsFeeds[community.id] || reset)
        ) {
          let filter = Sections.PostsNoReplies
          if (community.community_type) {
            filter = community.community_type as Sections
          }
          acc[community.id] = {
            idx,
            communityPostsFeed: new PostsFeedModel(
              this.rootStore,
              'custom',
              {
                feed: community.uri,
              },
              {
                isSimpleFeed: ['posts_with_media'].includes(filter),
              },
            ),
          }
        }
        return acc
      },
      {},
    )

    runInAction(() => {
      this._communities = reset ? map : merge(this._communities, map)
      this._communityFeeds = reset
        ? feedMap
        : merge(this._communityFeeds, feedMap)
      this._communityPostsFeeds = reset
        ? postsFeedMap
        : merge(this._communityPostsFeeds, postsFeedMap)
    })
  }

  _getAllUsersInACommunity = actions.wrapAction(
    async (did: string,cid:string) => {

      const url = `${SOLARPLEX_FEED_API}/splx/get_users_by_community`
      const response = await this.rootStore.api.post<{
        data: member[]
      }>(url,{
        body: {
          did,
          cid
        }
      })
      if (!response || this.rootStore.api.postError(url, { body: { did, cid }})) {
        return
      }
      return response.data
    },
    this,
    '_getAllUsersInACommunity'
  )

  _getAllUsersInCommunities = actions.wrapAction(
    async () => {
      const communities = this.communities
      const usersByCommunity: Record<string, member[]> = {}
      const promises = communities.map(async (community) => {
        const did = this.rootStore.me.did;
        if (!did) {
          return
        }
        const users = await this._getAllUsersInACommunity(this.rootStore.me.did, community.id)
   
        usersByCommunity[community.id] = users || []
      })
      await Promise.all(promises)
      runInAction(() => {
        this.usersByCommunity = usersByCommunity
      })
    },
    this,
    '_getAllUsersInCommunities'
  )

  _getAllCommunities = actions.wrapAction(
    async () => {
      const url = `${SOLARPLEX_FEED_API}/splx/get_all_communities`
      const response = await this.rootStore.api.get<{
        data: SolarplexCommunity[]
      }>(url)
      if (!response || this.rootStore.api.getError(url)) {
        return
      }
      return response.data
    },
    this,
    '_getAllCommunities',
  )

  fetchAllUserProfilesInCommunity = actions.wrapAction(async (cid: string) =>  {

    if (this._allUsersProfilesCache[cid]) {
      this.allUsersProfiles[cid] = this._allUsersProfilesCache[cid];
       return;
    }

    const users = this.usersByCommunity[cid] ?? [];
    if (users.length === 0) {
      return;
    }
  
    const chunkSize = 25; // Set the chunk size to 25

    const promises = users.reduce((acc: string[][], user, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }
      if (user.uid !== null) {
        acc[chunkIndex].push(user.uid);
      }
      return acc;
    }, []).map(chunk => this.rootStore.agent.app.bsky.actor.getProfiles({ actors: chunk }));
  
    const results = await Promise.all(promises);
    runInAction(() => {
      const userProfiles = results.flatMap(res => res && res.data.profiles.length !== 0 ? res.data.profiles : []);
      this.allUsersProfiles[cid] = userProfiles;
      this.rootStore.me.follows.hydrateProfiles(userProfiles);

      this._allUsersProfilesCache[cid] = userProfiles;
    })
   
  },
  this,
  'fetchAllUserProfilesInCommunity'
  )

  fetchAllUserProfilesInCommunityError(cid: string) {
    return actions.error(
      'fetchAllUserProfilesInCommunity',
      this,
      [cid],
    )
  }

  fetchAllUserProfilesInCommunityBusy(cid: string) {
    return actions.isBusy(
      'fetchAllUserProfilesInCommunity',
      this,
      [cid],
    )
  }

  _fetchTopUserProfilesInCommunity = actions.wrapAction(async(cid: string) => {

    if (this._communityUsersProfilesCache[cid]) {
      this.communityUsersProfiles[cid] = this._communityUsersProfilesCache[cid];
       return;
    }

    const users = this.usersByCommunity[cid] ?? [];
    if (users.length === 0) {
      return
    }

    const topUsers = users.slice(-5)
      try {
        const res = await this.rootStore.agent.app.bsky.actor.getProfiles({
          actors: topUsers.filter(u => u.uid !== null).map(u => u.uid),
        });
        if (res && res.data.profiles.length !== 0) {
          const profileData = res.data.profiles;
          const topProfiles = profileData.slice(0,3);
          this.communityUsersProfiles[cid] = topProfiles;
        }
      } catch (error) {
        console.log("Community Fetch error",error);
        this.rootStore.log.error('Failed to fetch profile for user', error);
      }
  },
  this,
  '_fetchTopUserProfilesInCommunity'
  )



  _fetchAllUserProfilesInCommunities = actions.wrapAction( async () => {
    const communities = this.communities
    const promises = communities.map(async (community) => {
      this.allUsersProfiles[community.id] = []
      await this.fetchAllUserProfilesInCommunity(community.id)
    })
    await Promise.all(promises)
  },
  this,
  '_fetchAllUserProfilesInCommunities'
  )

   _fetchTopUserProfilesInCommunities = actions.wrapAction(
    async () => {
      const communities = this.communities
      const promises = communities.map(async (community) => {
        this.communityUsersProfiles[community.id] = []
        await this._fetchTopUserProfilesInCommunity(community.id)
      })
      await Promise.all(promises)
    },
    this,
    '_fetchTopUserProfilesInCommunities'
   )

   get _fetchTopUserProfilesInCommunitiesBusy() {
    return actions.isBusy(
      '_fetchTopUserProfilesInCommunities',
      this,
      [],
    )
   }


  // TODO(zfaizal2): fix db for communities to add handle
  _fetch = actions.wrapAction(
    async (reset: boolean = false) => {
      const [communities] = await Promise.all([this._getAllCommunities()])
      try {
        this.rootStore.me.joinedCommunities.updateCache(reset)
      } catch (err) {}
      communities?.map(c => {
        c.uri = makeRecordUri(SOLARPLEX_DID, 'app.bsky.feed.generator', c.id)
      })
      this._setCommunities(communities ?? [], reset)
      await this._getAllUsersInCommunities()
      await Promise.all([
        this._fetchTopUserProfilesInCommunities(),
        this._fetchAllUserProfilesInCommunities()
      ]);
    },
    this,
    '_fetch',
  )

  fetch = actions.wrapAction(
    async () => {
      return await this._fetch(true)
    },
    this,
    'fetch',
  )
}
