import {observer} from 'mobx-react-lite'
import {CreateAccount} from 'view/com/auth/create/CreateAccount'
import {NativeStackScreenProps, CommonNavigatorParams} from 'lib/routes/types'
import {InviteCodes} from 'lib/constants'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Deans'>
export const DeanListScreen = observer(({}: Props) => {
  const name = 'breakpoint'
  const inviteCode = InviteCodes[name as keyof typeof InviteCodes]
  return <CreateAccount inviteCode={inviteCode} onPressBack={() => {}} />
})
