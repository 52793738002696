import {StyleProp, StyleSheet, ViewStyle} from 'react-native'

import React from 'react'
import {LoadingIndicator} from 'lib/icons'
import {Text} from 'view/com/util/text/Text'
import {TouchableOpacity} from 'react-native-gesture-handler'
import {colors} from 'lib/styles'
import {useTheme} from 'lib/ThemeContext'

type ClaimBtnProps = {
  onClick: () => void
  text?: string
  loading?: boolean
  done?: boolean
  disabled?: boolean
  weekly?: boolean
  MissionType?: string
  btnstyle?: StyleProp<ViewStyle>
}

export const ClaimBtn = ({
  onClick,
  text = 'Claim Reward',
  loading = false,
  disabled = false,
  done = false,
  weekly = false,
  btnstyle,
}: ClaimBtnProps) => {
  const theme = useTheme()
  const disableBgStyle =
    theme.colorScheme === 'dark' ? styles.disabledDark : styles.disabledLight
  const disableTextStyle =
    theme.colorScheme === 'dark'
      ? styles.disabledTextDark
      : styles.disabledTextLight
  return (
    <TouchableOpacity
      accessibilityRole="button"
      disabled={disabled || loading || done}
      onPress={onClick}
      style={[
        btnstyle,
        styles.claimBtn,
        weekly ? styles.claimWeeklyBtn : styles.claimDailyBtn,
        disabled && disableBgStyle,
      ]}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Text
            style={[
              weekly ? styles.claimWeeklyText : styles.claimDailyText,
              disabled && disableTextStyle,
            ]}>
            {text}
          </Text>
        </>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  claimBtn: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 24,
  },
  claimDailyBtn: {
    backgroundColor: colors.splx.primary[50],
  },
  claimDailyText: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: '600',
  },
  claimWeeklyBtn: {
    backgroundColor: colors.splx.accent.canary[30],
    // borderWidth: 1,
    // borderColor: colors.splx.primary[60],
  },
  claimWeeklyText: {
    color: colors.splx.primary[60],
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  disabledDark: {
    backgroundColor: colors.gray4,
  },
  disabledLight: {
    backgroundColor: colors.gray2,
  },
  disabledTextDark: {
    color: colors.gray6,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  disabledTextLight: {
    color: colors.gray4,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  starIcon: {
    marginRight: 8,
  },
})
