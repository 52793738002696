import {useTheme} from 'lib/ThemeContext'
import React from 'react'
import {SolarplexLogo, SolarplexLogoDark} from 'lib/icons'

export const BrandLogo = () => {
  const theme = useTheme()

  return (
    <>
      {theme.colorScheme === 'dark' ? <SolarplexLogoDark /> : <SolarplexLogo />}
    </>
  )
}
