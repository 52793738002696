import {StyleSheet, View} from 'react-native'

import {Button} from '../../../../view/com/util/forms/Button'
import { FoafsModel } from '../../../../state/models/discovery/foafs'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import { OnboardingActorsModel } from '../../../../state/models/discovery/onboarding-actors'
import { OnboardingHeader } from './Header'
import React from 'react'
import { SuggestedActorsModel } from '../../../../state/models/discovery/suggested-actors'
import { Suggestions } from '../../search/Suggestions'
import {Text} from '../../../../view/com/util/text/Text'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { observer } from 'mobx-react-lite'
import {s} from '../../../../lib/styles'
import {usePalette} from '../../../../lib/hooks/usePalette'
import { useStores } from '../../../../state'

export const Followers = observer(({next}: {next: () => void}) => {
  const pal = usePalette('default')
  const store = useStores()
  const foafs = React.useMemo<FoafsModel>(
    () => new FoafsModel(store),
    [store],
  )
  const suggestedActors = React.useMemo<OnboardingActorsModel>(
    () => new OnboardingActorsModel(store),
    [store],
  )

  React.useEffect(() => {
    if (!foafs.hasData) {
      foafs.fetch()
    }
    if (!suggestedActors.hasLoaded) {
      suggestedActors.loadMore(true)
    }
  },[])
  return (
    <View style={[styles.container]}>
      <View testID="welcomeScreen" style={[s.flexCol]}>
        <OnboardingHeader title='Follow some people!' desc='At the moment you aren’t following anyone, pick some of the Solarplex community members we chose for you!' step='1' />
        <View style={styles.spacer} />
        <View style={[{maxHeight: 300,borderTopWidth: 1,borderBottomWidth: 1,width:'100%'},pal.border]}>
        <Suggestions foafs={foafs} suggestedActors={suggestedActors} isOnboarding={true} />
        </View>
      </View>
      <Button
        onPress={next}
        style={{
          width:'100%',
          margin: 20,
          marginBottom: 0,
        }}
        label="Next"
        testID="continueBtn"
        labelStyle={styles.buttonText}
      />
    </View>
  )
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 15,
    paddingHorizontal: 20,
    justifyContent: 'space-between',
    alignItems:'center',
  },
  title: {
    fontSize: 48,
    fontWeight: '800',
  },
  row: {
    flexDirection: 'row',
    columnGap: 20,
    alignItems: 'center',
    marginVertical: 20,
  },
  rowText: {
    flex: 1,
  },
  spacer: {
    height: 20,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 18,
    marginVertical: 4,
  },
})
